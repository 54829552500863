<template>
  <b-card class="blog-edit-wrapper" :title="formTitle">
    <!-- form -->
    <validation-observer ref="popupRules">
      <b-form class="mt-2">
        <b-row>
          <b-col md="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="text-center">
                <b>Medio Principal</b>
              </h4>
              <div class="text-center">
                <b-form-group label="" label-for="">
                  <b-row class="match-height">
                    <b-col lg="8" md="6" sm="12">
                      <div class="boxsv boxsv1" v-if="medio">
                        <b-img
                          v-if="medio.tipo == 'IMAGEN'"
                          :src="medio.url"
                          center
                          thumbnail
                          fluid
                          class="rounded"
                          alt="Card image cap"
                        />
                        <b-img
                          v-else-if="medio.miniatura"
                          thumbnail
                          fluid
                          center
                          class="rounded"
                          :src="medio.miniatura"
                          alt="Card image cap"
                        />
                        <b-img
                          v-else
                          thumbnail
                          fluid
                          center
                          class="rounded"
                          :src="
                            require(`@/assets/images/icons/file-icon/${medio.extencion}.png`)
                          "
                          alt="Card image cap"
                        />
                      </div>
                    </b-col>
                    <b-col lg="4" md="6" sm="12">
                      <div v-if="medio">
                        <b-row class="mb-1">
                          <b-col cols="12">
                            <h5 class="text-capitalize mb-75">Titulo:</h5>
                            <b-card-text>
                              {{ medio.titulo }}
                            </b-card-text>
                          </b-col>
                          <b-col cols="12">
                            <h5 class="text-capitalize mb-75">Categoria:</h5>
                            <b-card-text>
                              {{ medio.categoria.nombre }}
                            </b-card-text>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col lg="12">
                            <h5 class="text-capitalize mb-75">URL</h5>
                            <b-form-group>
                              <b-input-group>
                                <b-form-input disabled v-model="medio.url" />
                                <b-input-group-append>
                                  <b-button v-clipboard:copy="medio.url">
                                    Copiar
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row class="mb-1">
                          <b-col lg="6" md="6" sm="12">
                            <h5 class="text-capitalize mb-75">Tipo:</h5>
                            <b-card-text>
                              {{ medio.extencion }}
                            </b-card-text>
                          </b-col>
                          <b-col lg="6" md="6" sm="12">
                            <h5 class="text-capitalize mb-75">Tamaño:</h5>
                            <b-card-text>
                              <span v-show="medio.peso"
                                >{{ medio.peso }} Kb.</span
                              >
                            </b-card-text>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="12" md="12" sm="12">
                            <h5 class="text-capitalize mb-75">Fecha Subida:</h5>
                            <b-card-text>
                              {{ medio.created_at }}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- <div class="box-imagen box-imagen1 border rounded">
                    <b-img
                      v-if="imagen"
                      :src="imagen"
                      center
                      fluid
                      thumbnail
                      class="rounded"
                      alt="Card image cap"
                    />
                  </div> -->
                </b-form-group>
                <b-button
                  v-b-modal.modal-seleccionar-archivo-popup
                  variant="flat-dark"
                  size="sm"
                >
                  <feather-icon icon="ImageIcon" class="mr-50" />
                  <span class="align-middle">Elegir Medio</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="nombre" class="mb-2">
               <label for="nombre"
                        >Nombre Popup <span class="text-danger">(*)</span></label
                      >
              <validation-provider
                #default="{ errors }"
                name="Nombre popup"
                rules="required"
              >
                <b-form-input
                  id="nombre"
                  v-model="popup.nombre"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
                <!-- <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Titulo Popup" label-for="titulo" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Titulo Popup"
                rules=""
              >
                <b-form-input
                  id="titulo"
                  v-model="popup.titulo"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.titulo"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
                <!-- <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Fecha Publicacion"
              label-for="Fecha Publicacion"
            >
              <validation-provider
                #default="{ errors }"
                name="Fecha Publicacion"
                rules="required"
              >
                <flat-pickr
                  v-model="popup.published_at"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.published_at"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Estado" label-for="Estado">
              <v-select
                id="Estado"
                v-model="estadoa"
                label="title"
                :options="estadoOpciones"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Diseño" label-for="disenio">
              <v-select
                id="disenio"
                v-model="disenioa"
                label="title"
                :options="disenioOpciones"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="contenido" label-for="">
              <b-button
                v-b-modal.modal-seleccionar-archivo
                variant="flat-dark"
                size="sm"
              >
                <feather-icon icon="ImageIcon" class="mr-50" />
                <span class="align-left">Medios</span> </b-button
              ><b-button
                v-b-modal.modal-seleccionar-enlace
                variant="flat-dark"
                size="sm"
              >
                <feather-icon icon="LinkIcon" class="mr-50" />
                <span class="align-left">Enlaces</span>
              </b-button>
              <validation-provider
                #default="{ errors }"
                name="Contenido"
                rules=""
              >
                <CkEditorPersonalizado
                  ref="ckeditor"
                  v-model="popup.contenido"
                  
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.contenido"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <h3 class="pt-2"></h3>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              id="formulario"
              v-model="popupactivo"
              value=1
              unchecked-value=0
              switch
            >
              <b>Activar Popup</b>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="validar"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'popups' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row />

    <!--/ form -->
    <!--Componente Medios -->
    <MediosComponent @enviarMedia="recibirMedia"></MediosComponent>
    <MediosPopupComponent
      @enviarMedia="recibirMedioPopup"
    ></MediosPopupComponent>
    <!--Componente Imagen -->
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
    <EnlacesComponent @enviarUrl="recibirUrl"></EnlacesComponent>
  </b-card>
</template>

<script>
import axios from "@axios";
import {
  required,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import {
  BFormCheckbox,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BFormGroup,
  BInputGroupAppend,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTimepicker,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import CkEditorPersonalizado from "@/componentes/CkEditorPersonalizado";
import MediosComponent from "@/componentes/MediosComponent.vue";
import MediosPopupComponent from "@/componentes/MediosPopupComponent.vue";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import flatPickr from "vue-flatpickr-component";
import router from "@/router";

import EnlacesComponent from "@/componentes/EnlacesComponent.vue";
const Spanish = require('flatpickr/dist/l10n/es');
localize("es", es);

export default {
  components: {
    BFormCheckbox,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    CkEditorPersonalizado,
    BFormTimepicker,
    BFormDatepicker,
    BFormTextarea,
    MediosComponent,
    ImagenComponent,
    MediosPopupComponent,
    flatPickr,
    EnlacesComponent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,

      min,
      errores: [],

      selectedForm: [],

      estadoOpciones: [
        { id: 1, title: "Publicado" },
        { id: 2, title: "Borrador" },
        { id: 3, title: "Papelera" },
      ],
      disenioOpciones: [
        {
          id: 1,
          title: "Modelo 1",
        },
        {
          id: 2,
          title: "Modelo 2",
        },
        {
          id: 3,
          title: "Modelo 3",
        },
      ],
      disenioa: { id: 1, title: "Modelo 1" },
      estadoa: { id: 2, title: "Borrador" },
      medio: null,

      popup: {
        nombre: "",
        titulo: "",

        published_at: null,
      },
      popupFile: null,
      imagenMiniatura: "",
      popupOption: [],
      popupactivo: 0,
    };
  },
  computed: {
    formTitle() {
      return this.popup.id ? "Editar Popup" : "Nuevo Popup";
    },
  },
  watch: {
    "popup.nombre": function () {
      this.errores.nombre = [];
    },
    "popup.contenido": function () {
      this.errores.contenido = [];
    },
    "popupactivo": function () {
      this.errores.activo = [];
    },
  },
  created() {
    this.date();
    if (router.currentRoute.params.id) {
      axios
        .get(`/popups/${router.currentRoute.params.id}`)
        .then((response) => {
          this.popup = response.data.data;
          this.medio=this.popup.medio;
          this.popupactivo = this.popup.activo;
          const valor = this.estadoOpciones.find(
            (estado) => estado.id === this.popup.estado
          );
          this.estadoa = { id: valor.id, title: valor.title };
          const valord = this.disenioOpciones.find(
            (disenio) => disenio.title === this.popup.disenio
          );
          this.disenioa = { id: valord.id, title: valord.title };
          
        })
        .catch((error) => console.error(error));
    }
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const imagen = ref("");

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
        imagen.value = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      imagen,
    };
  },

  methods: {
    date() {
      const hoy = new Date();
      var date =
        hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();
      var time = hoy.getHours() + ":" + hoy.getMinutes();
      this.popup.published_at = date + " " + time;
    },
    async success(Mensaje) {
      await this.$swal({
        title: Mensaje,

        icon: "success",
        timer: 2000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      router.push({ name: "popups" });
    },


    alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`);
    },
    validar() {
      this.$refs.popupRules.validate().then((success) => {
        if (success) {
          this.guardar();
        }
      });
    },
    async guardar() {
      this.popup.activo=this.popupactivo;
      this.popup.disenio=this.disenioa.title;
      this.popup.estado = this.estadoa.id;
      if (this.medio!=null)
        this.popup.medio_id=this.medio.id;  

      
      if (this.popup.id) {
        //formData.append("_method", "PUT");
        axios
          .put(`/popups/${this.popup.id}`, this.popup)
          .then((response) => {
            this.success("Actualizado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      } else {
        axios
          .post("/popups", this.popup)
          .then((response) => {
            this.success("Insertado con Exito");
            // router.push({ name: 'popups' });
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      }
    },

    recibirUrl(enlace) {
      //console.log(enlace);
      var itemEnlace = enlace;

      const content = `<a href="${itemEnlace.url}">${itemEnlace.titulo}</a>`;
      const viewFragment =
        this.$refs.ckeditor.editorCk.data.processor.toView(content);
      const modelFragment =
        this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
      this.$refs.ckeditor.editorCk.model.insertContent(
        modelFragment,
        this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
      );
    },

    recibirMedia(media) {
      if (media.tipo == "IMAGEN") {
        // this.$refs.ckeditor.editorCk
        const content = `<figure class="image"><img src="${media.url}"></figure>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      } else if (media.tipo == "VIDEO") {
        const content = `<figure class="media"><oembed url="${media.url}"></oembed></figure>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      } else {
        const content = `<a href="${media.url}">${media.titulo}</a>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      }
    },

    recibirMedioPopup(medio) {
     // console.log(medio);
      this.medio = medio;
      this.imagen = medio.url;
    },
    // RECIBIR IMAGEN DEL COMPONENTE
    recibirImagen(imagen) {
      this.imagen = imagen.url;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "@core/scss/vue/libs/map-leaflet.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: "Buscar";
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>
