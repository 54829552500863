<template>
    <b-modal
        id="modal-seleccionar-archivo-popup"
        ref="my-modal"
        title="Selecione un archivo"
        no-close-on-backdrop
        ok-title="Insertar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        size="xl"
        @cancel="cerrarModal"
        @close="cerrarModal"
        :ok-disabled="selected==''"
        @ok.prevent="validarSeleccionar"
    >
    <Index @selectItemMedia="selectItemMedia" id="scroll"/>
  </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import {
  BButton, BModal, VBModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import Index from "@/views/aplicaciones/medios/Index.vue";

export default {
  components: {
    BButton,
    BModal,
    Index,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
        selected:'',
    }
  },
  created() {

  },
  watch: {

  },
  methods: {
    cerrarModal(){
      this.selected='';
    },
    selectItemMedia(item){
      this.selected=item;
    },
    validarSeleccionar(){
      this.$nextTick(() => {
          this.$refs['my-modal'].toggle('#toggle-btn')
      })
      this.$emit('enviarMedia',this.selected);
    }
    
  },
}
</script>
<style lang="scss">
#scroll{
  .scroll-area1 {
    height: calc(var(--vh, 1vh) * 100 - 29rem) !important;
  }
}
#modal-seleccionar-archivo-popup{
  .modal-body {
      padding: 0rem !important;
  }
  .card{
    margin-bottom: 0rem !important;
  }
  #card-media{
    margin-bottom: 2rem !important;
  }
}

</style>
